interface IconsProps {
  className?: string;
  style?: React.CSSProperties;
}

const X: React.FC<IconsProps> = ({ className, style }) => {
  return (
    <svg 
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_149_312)">
        <path d="M10.4101 10.6477C10.4648 10.7352 12.8601 14.2188 15.7312 18.4023C18.6023 22.5859 20.9539 26.0313 20.9539 26.0586C20.9539 26.0914 18.5968 28.8586 15.7093 32.2164C12.8273 35.5688 10.432 38.3578 10.3882 38.407C10.3171 38.4945 10.3937 38.5 11.5148 38.5H12.7179L17.3609 33.1023C19.9148 30.1383 22.0312 27.6992 22.0585 27.6938C22.0914 27.6828 23.7867 30.1109 25.8265 33.0859L29.5398 38.5H33.6085C37.464 38.5 37.6718 38.4945 37.6226 38.4016C37.5953 38.3523 35.107 34.7266 32.0992 30.3516C29.0914 25.9766 26.625 22.3836 26.625 22.3672C26.625 22.3453 28.9109 19.6766 31.7054 16.4336L36.7804 10.5273L35.6046 10.5109C34.6367 10.5 34.4125 10.5109 34.3414 10.5766C34.2921 10.6203 32.296 12.9336 29.9062 15.7117C27.5164 18.4953 25.5421 20.7594 25.5203 20.7484C25.4984 20.732 23.9015 18.4242 21.971 15.6133L18.4601 10.5H14.3859H10.3171L10.4101 10.6477ZM25.8046 24.4781C30.4804 31.1719 34.3195 36.668 34.325 36.7008C34.3414 36.7336 33.6796 36.7445 32.4984 36.7391L30.65 36.7227L22.1296 24.5383C17.4429 17.8336 13.6093 12.3375 13.6093 12.3266C13.6093 12.3156 14.4406 12.3047 15.4578 12.3047L17.3007 12.3102L25.8046 24.4781Z" fill="#E3E4E6" fillOpacity="0.55"/>
      </g>
      <defs>
        <clipPath id="clip0_149_312">
          <rect width="28" height="28" fill="white" transform="translate(10 10.5)"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default X;
