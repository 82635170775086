interface IconsProps {
  className?: string;
  style?: React.CSSProperties;
}

const Instagram: React.FC<IconsProps> = ({ className, style }) => {
  return (
    <svg 
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_149_301)">
        <g clipPath="url(#clip1_149_301)">
          <path d="M16.6156 10.0814C15.041 10.3533 13.6137 11.1123 12.3789 12.3357C11.4047 13.3099 10.8043 14.2728 10.3625 15.5642L10.0566 16.4705V24.4568V32.4431L10.3625 33.3494C10.8043 34.6408 11.4047 35.6037 12.3789 36.5779C13.3531 37.5521 14.316 38.1525 15.6074 38.5943L16.5137 38.9001H24.5H32.4863L33.3926 38.5943C34.684 38.1525 35.6469 37.5521 36.6211 36.5779C37.5953 35.6037 38.1957 34.6408 38.6375 33.3494L38.9434 32.4431V24.4568V16.4705L38.6375 15.5642C38.1957 14.2728 37.5953 13.3099 36.6211 12.3357C35.6469 11.3615 34.684 10.7611 33.3926 10.3193L32.4863 10.0134L24.8398 10.0021C20.6371 9.99077 16.9328 10.0248 16.6156 10.0814ZM32.9055 12.8794C34.2762 13.4345 35.5223 14.6806 36.0773 16.0513L36.3379 16.697V24.4568V32.2166L36.0773 32.8623C35.5223 34.233 34.2762 35.4791 32.9055 36.0341L32.2598 36.2947H24.5H16.7402L16.0945 36.0341C14.7238 35.4791 13.4777 34.233 12.9227 32.8623L12.6621 32.2166L12.6281 24.808C12.6055 19.733 12.6395 17.2068 12.7188 16.799C13.1039 14.9412 14.7238 13.2419 16.5816 12.7435C17.0914 12.5962 18.4055 12.5736 24.7266 12.5962L32.2598 12.6189L32.9055 12.8794Z" fill="#E3E4E6" fillOpacity="0.55" />
          <path d="M31.3535 15.0771C30.8437 15.3716 30.3906 16.1306 30.3906 16.7084C30.3906 17.7279 31.4215 18.6341 32.4637 18.5322C34.2082 18.3736 34.752 16.1986 33.3133 15.1337C32.8375 14.7826 31.9199 14.7599 31.3535 15.0771Z" fill="#E3E4E6" fillOpacity="0.55" />
          <path d="M22.9254 17.1615C21.3621 17.5014 19.6629 18.5549 18.6773 19.8123C15.6754 23.6299 16.9668 29.1127 21.3508 31.1971C26.8449 33.8139 33.0641 28.8861 31.8066 22.9275C30.957 18.9287 26.9016 16.2892 22.9254 17.1615ZM26.5617 20.0615C27.6379 20.5826 28.3742 21.3303 28.9066 22.4178C29.2918 23.2334 29.3144 23.324 29.3144 24.4568C29.3144 25.5896 29.2918 25.6803 28.9066 26.4959C28.3742 27.5947 27.6379 28.331 26.5391 28.8635C25.7234 29.2486 25.6328 29.2713 24.5 29.2713C23.3672 29.2713 23.2766 29.2486 22.4609 28.8635C21.3621 28.331 20.6258 27.5947 20.0934 26.4959C19.7082 25.6803 19.6855 25.5896 19.6855 24.4568C19.6855 23.324 19.7082 23.2334 20.0934 22.4178C20.5805 21.4096 21.3394 20.6053 22.2684 20.1408C23.1746 19.6764 23.6164 19.5857 24.7492 19.631C25.5988 19.665 25.848 19.7217 26.5617 20.0615Z" fill="#E3E4E6" fillOpacity="0.55" />
        </g>
        <g clipPath="url(#clip2_149_301)">
          <path d="M-73.1334 -145.033C-91.6667 -141.833 -108.467 -132.9 -123 -118.5C-134.467 -107.033 -141.533 -95.6999 -146.733 -80.4999L-150.333 -69.8333V24.1667V118.167L-146.733 128.833C-141.533 144.033 -134.467 155.367 -123 166.833C-111.533 178.3 -100.2 185.367 -85 190.567L-74.3334 194.167H19.6666H113.667L124.333 190.567C139.533 185.367 150.867 178.3 162.333 166.833C173.8 155.367 180.867 144.033 186.067 128.833L189.667 118.167V24.1667V-69.8333L186.067 -80.4999C180.867 -95.6999 173.8 -107.033 162.333 -118.5C150.867 -129.967 139.533 -137.033 124.333 -142.233L113.667 -145.833L23.6666 -145.967C-25.8 -146.1 -69.4 -145.7 -73.1334 -145.033ZM118.6 -112.1C134.733 -105.567 149.4 -90.8999 155.933 -74.7666L159 -67.1666V24.1667V115.5L155.933 123.1C149.4 139.233 134.733 153.9 118.6 160.433L111 163.5H19.6666H-71.6667L-79.2667 160.433C-95.4 153.9 -110.067 139.233 -116.6 123.1L-119.667 115.5L-120.067 28.3001C-120.333 -31.4333 -119.933 -61.1666 -119 -65.9666C-114.467 -87.8333 -95.4 -107.833 -73.5334 -113.7C-67.5334 -115.433 -52.0667 -115.7 22.3333 -115.433L111 -115.167L118.6 -112.1Z" fill="black" />
          <path d="M1.13329 -61.6998C-17.2667 -57.6998 -37.2667 -45.2998 -48.8667 -30.4998C-84.2 14.4335 -69 78.9669 -17.4 103.5C47.2666 134.3 120.467 76.3002 105.667 6.16686C95.6666 -40.8998 47.9333 -71.9665 1.13329 -61.6998ZM43.9333 -27.5665C56.6 -21.4332 65.2666 -12.6331 71.5333 0.166862C76.0666 9.76687 76.3333 10.8335 76.3333 24.1669C76.3333 37.5002 76.0666 38.5669 71.5333 48.1669C65.2666 61.1002 56.6 69.7669 43.6666 76.0335C34.0666 80.5669 33 80.8335 19.6666 80.8335C6.3333 80.8335 5.26663 80.5669 -4.33337 76.0335C-17.2667 69.7669 -25.9334 61.1002 -32.2 48.1669C-36.7334 38.5669 -37 37.5002 -37 24.1669C-37 10.8335 -36.7334 9.76687 -32.2 0.166862C-26.4667 -11.6998 -17.5334 -21.1665 -6.60004 -26.6331C4.06663 -32.0998 9.26663 -33.1665 22.6 -32.6331C32.6 -32.2331 35.5333 -31.5665 43.9333 -27.5665Z" fill="black" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_149_301">
          <rect width="48" height="48" fill="white" />
        </clipPath>
        <clipPath id="clip1_149_301">
          <rect width="29" height="29" fill="white" transform="translate(10 9.5)" />
        </clipPath>
        <clipPath id="clip2_149_301">
          <rect width="341.333" height="341.333" fill="white" transform="translate(-151 -146.5)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default Instagram;
