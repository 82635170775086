interface IconsProps {
  className?: string;
  style?: React.CSSProperties;
}

const Mail: React.FC<IconsProps> = ({ className, style }) => {
  return (
    <svg 
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M41 19.1033V32.5376C41.0001 33.9073 40.476 35.2251 39.5353 36.2208C38.5946 37.2164 37.3086 37.8143 35.9411 37.8918L35.6375 37.9001H13.3625C11.9928 37.9002 10.6749 37.3761 9.67932 36.4354C8.68371 35.4947 8.0858 34.2087 8.00825 32.8412L8 32.5376V19.1033L23.9258 27.4457C24.103 27.5385 24.3 27.587 24.5 27.587C24.7 27.587 24.897 27.5385 25.0742 27.4457L41 19.1033Z" fill="#E3E4E6" fillOpacity="0.55" />
      <path d="M13.3623 11.5H35.6373C36.9666 11.4998 38.2485 11.9933 39.2344 12.8848C40.2204 13.7762 40.8401 15.0021 40.9734 16.3246L24.4998 24.9541L8.02625 16.3246C8.15416 15.0546 8.73088 13.8719 9.65273 12.9891C10.5746 12.1062 11.7811 11.5812 13.0554 11.5083L13.3623 11.5H35.6373H13.3623Z" fill="#E3E4E6" fillOpacity="0.55" />
    </svg>

  );
};

export default Mail;
