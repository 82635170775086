interface iconProps {
  className?: string;
  style?: React.CSSProperties;
}

const Phone: React.FC<iconProps> = ({ className, style }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M13.965 7.56756C12.8649 7.98742 10.8159 10.011 8.91141 12.544C8.1551 13.5558 7.5638 14.4644 7.2544 15.1114L7 15.6207L7.08251 16.3159C7.71506 21.7879 12.2667 28.9394 18.3722 34.0466C22.6832 37.6464 27.3311 39.9797 31.1608 40.4478C31.7933 40.5304 31.8277 40.5235 32.2265 40.3652C33.8491 39.6975 37.6857 36.6139 39.1365 34.8037C40.0578 33.6542 40.1953 33.0967 39.769 32.2295C39.4802 31.6375 39.1708 31.3691 37.5344 30.2678C34.2135 28.0239 31.8346 26.8125 30.7689 26.8125C29.9782 26.8125 29.5313 27.1429 28.2387 28.664C27.7849 29.194 27.3723 29.6346 27.3242 29.6346C27.1729 29.6346 26.0591 29.1803 25.3922 28.8499C23.0338 27.6591 21.0537 25.9108 19.5961 23.7151C19.0529 22.9029 18.2828 21.3818 18.0353 20.6453L17.8703 20.1566L18.1384 19.9157C18.2828 19.7849 18.7297 19.3995 19.1285 19.0553C20.3592 18.0091 20.648 17.603 20.6962 16.8459C20.7168 16.5293 20.6893 16.2952 20.5724 15.9304C20.3592 15.2765 19.6167 13.7623 18.9223 12.5991C18.1384 11.2775 16.447 8.73079 16.062 8.30404C15.5051 7.68457 14.5425 7.3473 13.965 7.56756Z" fill="#E3E4E6" fillOpacity="0.55"/>
    </svg>
  );
};

export default Phone;
